
.TagUsOuterDiv{
    object-fit: cover;
    background-color: #FDF8EC;
}


#TagUsHeading{
    font-family: Jost,sans-serif;
    margin: 3rem;
    text-align: center;
    color: #0A1FDB;
}


.TagUsCol{
    object-fit: cover;
}

.TagUsImg{
    width: 100%;
    height: 100%;
}

#TagUsRow1{
    width: 90%;
    margin: 3rem auto 1rem;
    /* margin-top: 1rem; */
    padding: 4rem 0;
}

#TagUsRow2{
    width: 90%;
    margin: 0.5rem auto;
    /* margin-top: 1rem; */
    padding: 0.5rem 0 7rem;
}


@media screen and (max-width:426px) {
    #TagUsHeading{
        margin: 2rem;
    }

    #TagUsRow1{
        /* margin: 1rem 0; */
        padding: 2rem 0;
        padding-bottom: 0px;
    }

    #TagUsRow2{
margin-top: 0px;
padding-top: 0%;
padding-bottom: 2rem;
    }

    .TagUsCol{
        /* padding: 0rem ; */
        /* margin: 0; */
        width: 50%;
        /* margin: ; */
        /* margin-top: 1rem; */
        margin-bottom: 10px;
    }
}
