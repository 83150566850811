

.MaskFeaturesHeading{
    font-family: Jost,sans-serif;
    color:#0A1FDB;
    margin: 3rem 0;
    text-align: center;
}

.MaskFeaturesText{
    font-family: Jost,sans-serif;
    color:#0A1FDB;
    margin-bottom: 2rem;
}

.MaskFeatureDesc{
    font-family: Jost,sans-serif;
    width: 80%;
  margin:0 auto ;

}


@media screen and (max-width:426px){
    .FeatureCard{
        margin-bottom: 1.5rem;
    }

    .MaskFeaturesHeading{
        margin: 2rem 0;
    }
}