

#PopularMasksHeader{
    text-align: center;
    color:#0A1FDB;
    font-family:Jost, sans-serif;
    margin-bottom: 3rem;
}



.card{
    text-align: center;
    border-radius: 0;
    object-fit: cover;
    
}

.CardTitle{
    font-family: Jost,sans-serif;
    font-size: 1.2vw;
    padding-bottom: 30px;
}
/* #long1{
    font-size: 18px;
} */
/* #long2{
    font-size: 15.6px;
    
} */

.CardText{
    font-family: Jost,sans-serif;
    color:#0A1FDB;
}

.card-img{
    position: relative;
    /* height: 80%; */
    
    object-fit: cover;
    height: 25em;
}


.QuickViewButton{
    /* color:transparent */
    position: absolute;
    z-index: 2;
    font-size: 16px;
    /* bottom: 16.5%; */
    bottom: 0;
    width: 100%;
    margin: 0;
    left: 0;
    border-radius: 0 !important;
    opacity: 0.9;
}

/* #quick1{
    margin-bottom: 16px;
}
#quick2{
    margin-bottom: 39px;
}
#quick3{
    margin-bottom: 39px;
}
#quick4{
    margin-bottom: 16px;
} */

@media screen and (max-width: 769px){
    .CardTitle{
        font-size: 1.3em;
    }
}

@media screen and (max-width:426px) {
    #PopularMasksHeader{
        margin-bottom: 2rem;

    }

    .MaskCard{
        margin-bottom: 1.5rem;
    }

    .QuickViewButton {
        position: absolute;
        /* top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); */
        z-index: 2;
        bottom: 0;
        /* left: auto; */
        /* height: 10%; */
        /* margin: 0 auto; */
        width: 100%;
        margin: 0;
        left: 0;
        border-radius: 0 !important;
        opacity: 0.6;
      }
      .CardTitle{
        font-size: 1em;
    }
}


  
  @media screen and (max-width: 376px) {
    .QuickViewButton {
      position: absolute;
      /* top: 50%;
          left: 50%;
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); */
      z-index: 2;
      bottom: 0;
      /* left: auto; */
      /* height: 10%; */
      /* margin: 0 auto; */
      width: 100%;
      margin: 0;
      left: 0;
      border-radius: 0 !important;
      opacity: 0.6;
    }
  }
  
  @media screen and (max-width: 321px) {
    .QuickViewButton {
      position: absolute;
      /* top: 50%;
          left: 50%;
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); */
      z-index: 2;
      bottom: 0;
      /* left: auto; */
      /* height: 10%; */
      /* margin: 0 auto; */
      width: 100%;
      margin: 0;
      left: 0;
      border-radius: 0 !important;
      opacity: 0.6;
    }
  }
  