@import url(https://fonts.googleapis.com/css2?family=Jost&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Habibi&family=Prompt&display=swap);

.navbarText{
    font-family: 'Jost', sans-serif;
   font-size: 2em;
}

.navbarBrandText{
    width: 100%;
    /* text-align: center; */
    /* margin-left: 7rem; */
    margin-right: 0;
}

.navbarLink{
    color: black;
    font-size: 1.4em;
}

#basic-navbar-nav{
    text-align: center;
}

@media screen and (max-width:769px){
     .navbarBrandText{
        width: 25%;
        margin-left: 0rem;
    }
    .navbarText{
        font-size: 1.5em;
    }

    .navbar-toggler {
        /* margin-right: 2rem; */
    }

}








#GetInTouchDiv {
  background-color: #fdf8ec;
  text-align: center;
  margin: 1.5rem 0;
}

#GetInTouchText{
    padding:7rem 0;
    color:#0A1FDB;
    font-family:Jost, sans-serif;
    font-size:5rem;
    white-space:nowrap;
}


@media screen and (max-width:426px) {
    #GetInTouchText{
        font-size: 2rem;
        padding: 5rem 0;
    }
}



.ContactInquiryCol1{
    border: 0.5px solid rgb(0, 0, 0,0.5);
    border-width: thin;
}

.ContactInquiryCol{
    border: 0.5px solid rgb(0, 0, 0,0.5);
    border-width: thin;
}

#InquirySection{
    padding: 10rem 3rem;
}

.InquiryText{
    font-family:Jost, sans-serif;
}

#InquiryForm{
    padding: 10rem 7rem;
}

.InquiryFormText{
    font-family: Jost,sans-serif;
}

.InquiryColouredText{
    color:#0A1FDB;
    /* font-weight: lighter; */
}

.lightText{
 font-size: 1.5vw;
}

.FormSubmitButton{
    padding: 0.5rem 3rem;
    background: transparent;
    border: 1px solid #0A1FDB ;
    border-radius: 10px;
    border-width: thin;
    margin: 0.5rem 0;
}

@media screen and (max-width:426px) {
    #InquirySection{
        padding: 3rem 2rem;
    }

    #InquiryForm{
        padding: 3rem 1rem;
    }

    .lightText{
        font-size: 4vw;
    }

    .SubjectInput{
        /* margin:  0; */
    }
}

#FooterDiv{
    
}

.FooterBrand{
    font-family: Jost,sans-serif;
 padding-top: 7rem;
}

.FooterColouredText{
    color:#0A1FDB;
    font-family:Jost, sans-serif;
}

.FooterLightText{
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
}

.FooterContactSection{
    font-family: Jost,sans-serif;
    font-size: 1.3rem;
}
.FooterContactSection a{
    text-decoration: none;
    transition: 0.3s;
}
.FooterContactSection a:hover{
    color: #bdc4ff;
}


@media screen  and (max-width:426px){
    .FooterBrand{
        /* margin-top: 4rem; */
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}


#ActualMainPicCont{
    object-fit: cover;
    position: relative;
    margin: 1rem 0 2.2rem;
    
}

.ActualMainPic{
    width: 100%;
    height: 80vh;
    
    object-fit: cover;
}

.ActualMainPicText{
    /* position: absolute; */
    /* position:relative; */
    /* top:40%;
    left: 10%; */
    /* width: 20%; */
    /* position: relative; */
}

.PicText{
    position: absolute;
    font-family: Jost,sans-serif;
    font-size: 6vw;
    top:40%;
    left: 10%;
    color: #fff;
    
}

.MainPicBtn{
    position: absolute;
    font-family: Jost,sans-serif;
    font-size: 1.5vw;
    top: 63%;
    left: 10%;
    /* background-color: transparent; */
    color:white;
}

@media screen and (max-width:426px) {
    .PicText{
        top: 37%;
        left: 10%;
        font-size: 2em;
    }

    #ActualMainPictCont{
        margin: 1rem 0 2rem;
        border: 10px solid;
    }

    .MainPicBtn{
        top: 50%;
        left: 15%;
        font-size: 2.5vw;
    }
}


#PopularMasksHeader{
    text-align: center;
    color:#0A1FDB;
    font-family:Jost, sans-serif;
    margin-bottom: 3rem;
}



.card{
    text-align: center;
    border-radius: 0;
    object-fit: cover;
    
}

.CardTitle{
    font-family: Jost,sans-serif;
    font-size: 1.2vw;
    padding-bottom: 30px;
}
/* #long1{
    font-size: 18px;
} */
/* #long2{
    font-size: 15.6px;
    
} */

.CardText{
    font-family: Jost,sans-serif;
    color:#0A1FDB;
}

.card-img{
    position: relative;
    /* height: 80%; */
    
    object-fit: cover;
    height: 25em;
}


.QuickViewButton{
    /* color:transparent */
    position: absolute;
    z-index: 2;
    font-size: 16px;
    /* bottom: 16.5%; */
    bottom: 0;
    width: 100%;
    margin: 0;
    left: 0;
    border-radius: 0 !important;
    opacity: 0.9;
}

/* #quick1{
    margin-bottom: 16px;
}
#quick2{
    margin-bottom: 39px;
}
#quick3{
    margin-bottom: 39px;
}
#quick4{
    margin-bottom: 16px;
} */

@media screen and (max-width: 769px){
    .CardTitle{
        font-size: 1.3em;
    }
}

@media screen and (max-width:426px) {
    #PopularMasksHeader{
        margin-bottom: 2rem;

    }

    .MaskCard{
        margin-bottom: 1.5rem;
    }

    .QuickViewButton {
        position: absolute;
        /* top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); */
        z-index: 2;
        bottom: 0;
        /* left: auto; */
        /* height: 10%; */
        /* margin: 0 auto; */
        width: 100%;
        margin: 0;
        left: 0;
        border-radius: 0 !important;
        opacity: 0.6;
      }
      .CardTitle{
        font-size: 1em;
    }
}


  
  @media screen and (max-width: 376px) {
    .QuickViewButton {
      position: absolute;
      /* top: 50%;
          left: 50%;
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); */
      z-index: 2;
      bottom: 0;
      /* left: auto; */
      /* height: 10%; */
      /* margin: 0 auto; */
      width: 100%;
      margin: 0;
      left: 0;
      border-radius: 0 !important;
      opacity: 0.6;
    }
  }
  
  @media screen and (max-width: 321px) {
    .QuickViewButton {
      position: absolute;
      /* top: 50%;
          left: 50%;
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); */
      z-index: 2;
      bottom: 0;
      /* left: auto; */
      /* height: 10%; */
      /* margin: 0 auto; */
      width: 100%;
      margin: 0;
      left: 0;
      border-radius: 0 !important;
      opacity: 0.6;
    }
  }
  
.modal-content{
    background-color: white;
    /* text-align: center; */
}

.btn-close{
    /* background-color: black; */
}

.modal-header{
    border-bottom: none;
}

.modal-footer{
    border-top: none;
}

.ModalText{
    font-family: Jost,sans-serif;
    /* color: red; */
    /* margin-top: 1rem; */
}

.ModalTextCol{
    margin-top: 1.5rem;
}

.Modal-img{
    width: 100%;   
}
/* .my-modal{
    width: 70vw; 
    max-width: 90vw;
} */

@media  screen and (max-width:426px) {
    .modal-content{
        text-align: center;
    }

    .Modal-img{
        width: 80%;   
    }
}



.MaskFeaturesHeading{
    font-family: Jost,sans-serif;
    color:#0A1FDB;
    margin: 3rem 0;
    text-align: center;
}

.MaskFeaturesText{
    font-family: Jost,sans-serif;
    color:#0A1FDB;
    margin-bottom: 2rem;
}

.MaskFeatureDesc{
    font-family: Jost,sans-serif;
    width: 80%;
  margin:0 auto ;

}


@media screen and (max-width:426px){
    .FeatureCard{
        margin-bottom: 1.5rem;
    }

    .MaskFeaturesHeading{
        margin: 2rem 0;
    }
}


.StandForHeading{
    margin: 3.5rem 0 3rem;
text-align: center;
color: #0A1FDB;
font-family: Jost,sans-serif;
}

#StandForCol1{
    padding: 0;

    /* width: 50%; */
    /* margin-right: 1px; */
    padding-right: 10px;
    background-color: #D9ECFF;
}

#StandForCol2{
    object-fit: cover;
    padding: 0;
    /* width: 50%; */
    padding-left: 40px;
    margin-right: 0;
}

#StandForPara{
    width: 60%;
    text-align: center;
    margin:12rem  auto;
   font-family: Jost,sans-serif;
   color:#0A1FDB;
   font-size: 1.5rem;
   font-weight: lighter;
   /* font-weight: 100; */
}

@media screen and (max-width:426px) {
    .StandForHeading{
        margin: 1.5rem 0;
    }

    #StandForPara{
       font-size: 1.1rem;
       margin: 5rem auto;
    }

    #StandForCol1{
        margin-bottom: 1rem;
    }

    #StandForCol2{
        padding: 0;
    }
}

.TagUsOuterDiv{
    object-fit: cover;
    background-color: #FDF8EC;
}


#TagUsHeading{
    font-family: Jost,sans-serif;
    margin: 3rem;
    text-align: center;
    color: #0A1FDB;
}


.TagUsCol{
    object-fit: cover;
}

.TagUsImg{
    width: 100%;
    height: 100%;
}

#TagUsRow1{
    width: 90%;
    margin: 3rem auto 1rem;
    /* margin-top: 1rem; */
    padding: 4rem 0;
}

#TagUsRow2{
    width: 90%;
    margin: 0.5rem auto;
    /* margin-top: 1rem; */
    padding: 0.5rem 0 7rem;
}


@media screen and (max-width:426px) {
    #TagUsHeading{
        margin: 2rem;
    }

    #TagUsRow1{
        /* margin: 1rem 0; */
        padding: 2rem 0;
        padding-bottom: 0px;
    }

    #TagUsRow2{
margin-top: 0px;
padding-top: 0%;
padding-bottom: 2rem;
    }

    .TagUsCol{
        /* padding: 0rem ; */
        /* margin: 0; */
        width: 50%;
        /* margin: ; */
        /* margin-top: 1rem; */
        margin-bottom: 10px;
    }
}

