@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');

.navbarText{
    font-family: 'Jost', sans-serif;
   font-size: 2em;
}

.navbarBrandText{
    width: 100%;
    /* text-align: center; */
    /* margin-left: 7rem; */
    margin-right: 0;
}

.navbarLink{
    color: black;
    font-size: 1.4em;
}

#basic-navbar-nav{
    text-align: center;
}

@media screen and (max-width:769px){
     .navbarBrandText{
        width: 25%;
        margin-left: 0rem;
    }
    .navbarText{
        font-size: 1.5em;
    }

    .navbar-toggler {
        /* margin-right: 2rem; */
    }

}