







#GetInTouchDiv {
  background-color: #fdf8ec;
  text-align: center;
  margin: 1.5rem 0;
}

#GetInTouchText{
    padding:7rem 0;
    color:#0A1FDB;
    font-family:Jost, sans-serif;
    font-size:5rem;
    white-space:nowrap;
}


@media screen and (max-width:426px) {
    #GetInTouchText{
        font-size: 2rem;
        padding: 5rem 0;
    }
}

