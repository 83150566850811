

.StandForHeading{
    margin: 3.5rem 0 3rem;
text-align: center;
color: #0A1FDB;
font-family: Jost,sans-serif;
}

#StandForCol1{
    padding: 0;

    /* width: 50%; */
    /* margin-right: 1px; */
    padding-right: 10px;
    background-color: #D9ECFF;
}

#StandForCol2{
    object-fit: cover;
    padding: 0;
    /* width: 50%; */
    padding-left: 40px;
    margin-right: 0;
}

#StandForPara{
    width: 60%;
    text-align: center;
    margin:12rem  auto;
   font-family: Jost,sans-serif;
   color:#0A1FDB;
   font-size: 1.5rem;
   font-weight: lighter;
   /* font-weight: 100; */
}

@media screen and (max-width:426px) {
    .StandForHeading{
        margin: 1.5rem 0;
    }

    #StandForPara{
       font-size: 1.1rem;
       margin: 5rem auto;
    }

    #StandForCol1{
        margin-bottom: 1rem;
    }

    #StandForCol2{
        padding: 0;
    }
}