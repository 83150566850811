@import url('https://fonts.googleapis.com/css2?family=Habibi&family=Prompt&display=swap');

.modal-content{
    background-color: white;
    /* text-align: center; */
}

.btn-close{
    /* background-color: black; */
}

.modal-header{
    border-bottom: none;
}

.modal-footer{
    border-top: none;
}

.ModalText{
    font-family: Jost,sans-serif;
    /* color: red; */
    /* margin-top: 1rem; */
}

.ModalTextCol{
    margin-top: 1.5rem;
}

.Modal-img{
    width: 100%;   
}
/* .my-modal{
    width: 70vw; 
    max-width: 90vw;
} */

@media  screen and (max-width:426px) {
    .modal-content{
        text-align: center;
    }

    .Modal-img{
        width: 80%;   
    }
}