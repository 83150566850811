
.ContactInquiryCol1{
    border: 0.5px solid rgb(0, 0, 0,0.5);
    border-width: thin;
}

.ContactInquiryCol{
    border: 0.5px solid rgb(0, 0, 0,0.5);
    border-width: thin;
}

#InquirySection{
    padding: 10rem 3rem;
}

.InquiryText{
    font-family:Jost, sans-serif;
}

#InquiryForm{
    padding: 10rem 7rem;
}

.InquiryFormText{
    font-family: Jost,sans-serif;
}

.InquiryColouredText{
    color:#0A1FDB;
    /* font-weight: lighter; */
}

.lightText{
 font-size: 1.5vw;
}

.FormSubmitButton{
    padding: 0.5rem 3rem;
    background: transparent;
    border: 1px solid #0A1FDB ;
    border-radius: 10px;
    border-width: thin;
    margin: 0.5rem 0;
}

@media screen and (max-width:426px) {
    #InquirySection{
        padding: 3rem 2rem;
    }

    #InquiryForm{
        padding: 3rem 1rem;
    }

    .lightText{
        font-size: 4vw;
    }

    .SubjectInput{
        /* margin:  0; */
    }
}