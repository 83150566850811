

#ActualMainPicCont{
    object-fit: cover;
    position: relative;
    margin: 1rem 0 2.2rem;
    
}

.ActualMainPic{
    width: 100%;
    height: 80vh;
    
    object-fit: cover;
}

.ActualMainPicText{
    /* position: absolute; */
    /* position:relative; */
    /* top:40%;
    left: 10%; */
    /* width: 20%; */
    /* position: relative; */
}

.PicText{
    position: absolute;
    font-family: Jost,sans-serif;
    font-size: 6vw;
    top:40%;
    left: 10%;
    color: #fff;
    
}

.MainPicBtn{
    position: absolute;
    font-family: Jost,sans-serif;
    font-size: 1.5vw;
    top: 63%;
    left: 10%;
    /* background-color: transparent; */
    color:white;
}

@media screen and (max-width:426px) {
    .PicText{
        top: 37%;
        left: 10%;
        font-size: 2em;
    }

    #ActualMainPictCont{
        margin: 1rem 0 2rem;
        border: 10px solid;
    }

    .MainPicBtn{
        top: 50%;
        left: 15%;
        font-size: 2.5vw;
    }
}