
#FooterDiv{
    
}

.FooterBrand{
    font-family: Jost,sans-serif;
 padding-top: 7rem;
}

.FooterColouredText{
    color:#0A1FDB;
    font-family:Jost, sans-serif;
}

.FooterLightText{
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
}

.FooterContactSection{
    font-family: Jost,sans-serif;
    font-size: 1.3rem;
}
.FooterContactSection a{
    text-decoration: none;
    transition: 0.3s;
}
.FooterContactSection a:hover{
    color: #bdc4ff;
}


@media screen  and (max-width:426px){
    .FooterBrand{
        /* margin-top: 4rem; */
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}